import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NbDialogRef, NbDialogService, NbSelectComponent, NbToastrService } from '@nebular/theme';
import { AuthService } from '../../../@core/services/auth/auth.service';
import { DataService } from '../../../@core/services/data/data.service';
import { ToastService } from '../../../@core/services/toast/toast.service';
import { BaseComponent } from '../../client/base/base-component';
import { LanguageService } from '../../../@core/services/language/language.service';
import { PersonModel } from '../../../@core/models/person.model';
import { LocalDataSource } from 'ng2-smart-table';
import { timingSafeEqual } from 'crypto';
import { PersonSex } from '../../../@core/models/personSex.model';
import { NgSelectOption } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DosimeterPosition } from '../../../@core/models/dosimeterPosition.model';
import { Observable, of } from 'rxjs';
import { RecordOverviewComponent } from '../record-overview/record-overview.component';
import { AssignCompareComponent } from '../assign-compare/assign-compare.component';
import { DoseRecordModel } from '../../../@core/models/doseRecord';
import { CodeComment } from '../../../@core/models/codecomment.model';
import { UtilsService } from '../../../@core/services/utils/utils.service';
import { Practice } from '../../../@core/models/practice.model';

@Component({
  selector: 'ngx-edit-allocation',
  templateUrl: './edit-allocation.component.html',
  styleUrls: ['./edit-allocation.component.scss']
})
export class EditAllocationComponent extends BaseComponent implements OnInit, AfterViewInit {

  @Input("allocation") allocation:any;
  @ViewChild("allocatedMonitoringType") allocatedMonitoringType:NbSelectComponent;
  @ViewChild("allocatedPosition") allocatedPosition;
  @ViewChild('codeCommentSelect') codeCommentSelect;
  @ViewChild('codeCommentsSelect') codeCommentsSelect;
  @ViewChild('suborganizationSelect') suborganizationSelect;
  @ViewChild('personSelect') personSelect
  persons= [];
  monitoring_types=[];
  positions=[];
  practice = [];
  codeComment=[];
  codeComments=[];
  originalPersonName: string;
  showComments: boolean;
  selectedComments: any[] = [];
  person:any;
  initialPersonName: string = ''
  personName: string;
  oldDosimeterId:string;
  newDosimeterId: string;
  dosimeterStatus: string;
  oldPersonId:string;
  suborganizations= [];
  errored:boolean=false;
  allocationDatesChanged:boolean=false;
  filteredpersonsOptions$:  Observable<string[]>;
  isSuperadminUser:boolean=false;
  checked:boolean=true;

  currentDosimeter: string = "";
  doseInitialAllocStart: any;
  doseInitialAllocEnd: any;
  validDosimeter:Boolean=true;
  monitoringTypeChanged = false;
  source: LocalDataSource = new LocalDataSource();
  constructor(protected ref: NbDialogRef<EditAllocationComponent>, 
    private datepipe: DatePipe,protected dataService:DataService, protected authService:AuthService, 
    private utils:UtilsService,
    public ls: LanguageService, public dialogService: NbDialogService,
     public toastrService:NbToastrService) { 
    super(dataService, authService, toastrService)
  }
  ngAfterViewInit(): void {
    this.currentDosimeter = this.allocation.dosimeterId;
    this.isSuperadminUser=this.auth.isSuperadminRole()
   
  }


  protected reinit(ob){
    console.log("this data is ", this.allocation);
    this.initialPersonName= this.allocation.firstName + ' ' + this.allocation.lastName
    this.doseInitialAllocStart=this.allocation.allocStart;
    this.doseInitialAllocEnd=this.allocation.allocEnd;
    this.oldDosimeterId=this.allocation.dosimeterId;
    this.newDosimeterId=this.allocation.dosimeterId;
    this.validDosimeter=true;
    this.initialize();
  }
  private async initialize() {
    this.personSelect = this.allocation.firstName + ' ' + this.allocation.lastName;
    await this.getPosition();
    await this.getPractice();
    await this.getWearers(this.allocation.suborganizationId);
    await this.initSelectComment();
    await this.configComments();
    await this.initSelectComments();
   await this.getDataFromPerson();
    await this.getSuborganizations();
  }

  setMonitoringType(event) {
    console.log(event)

  }

  setPosition(event) {
    console.log("EVENT POSITION IS ", event)
    if(event && event.value != undefined) {
      this.allocation.position=event.value;
      console.log(event)
      console.log(this.allocation)
    } else {
      if(event === null || event === undefined) {
        this.allocation.position = "";
      }
    }
  };

  async getPosition () {
    let resp = await this.data.getGeneric(this.auth.user.token, new DosimeterPosition())
    this.positions = resp.docs;
    console.log('dosimeterPosition', this.positions)
  };

  async getWearers(suborganizationId) {
    console.log("get wearers ", suborganizationId, "this.allocation is ", this.allocation)
    let resp = await this.dataService.getOrganizatonPersons(suborganizationId, this.auth.user.token, true, this.allocation);
    this.persons = resp.persons;
    console.log("PERRRSONS Are ", this.persons)
    this.filteredpersonsOptions$= of(this.persons);
    this.person=this.persons.filter(elem=>elem['_id']==this.allocation.personId)[0];
    if (this.person!=undefined && this.person.monitoring_type) {
   this.monitoring_types=this.person.monitoring_type
    console.log("PPPPPPPPPPPPPPP-----------------monitoring type is ", this.monitoring_types)
    } else {
      console.log("RRRRRRRRRRRRRRR----------------monitoring type other", this.monitoring_types)
    let _org = await this.data.getOrganizationDetails(this.allocation.organizationId, this.auth.user.token);
    let _m=[];
    for (var k in _org.docs[0].services) {
      let s=_org.docs[0].services[k];
      _m.push({monitoring_type: s.measurement})
    }
    this.monitoring_types=_m; 
    console.log("MONI type is ", this.monitoring_types);

   
    this.showToast('danger', this.ls.strings.inactive_person, "");
      this.monitoringTypeChanged = true}
try{
    this.allocatedMonitoringType.selected=this.monitoring_types.filter(elem=>elem.monitoring_type==this.allocation.monitoring_type)[0];
} 
catch (e) {

} 
try{
    this.allocatedPosition.selected=this.positions.filter(elem=>elem['value']==this.allocation.position)[0];
}
catch(e) {

}
  };

  private _filter(name: string): any[] {
    const filterValue = name.toString().toLowerCase();

    return this.persons.filter(pers =>
      pers.firstName.toLowerCase().includes(filterValue) ||
      pers.lastName.toLowerCase().includes(filterValue)
    );
  }


  onPersonChange(name: string) {
    // if (!name && this.originalPersonName) {
    //   this.personName = this.originalPersonName;
    // } else {
    //   this.filteredpersonsOptions$ = of(this._filter(name));
    // }
     this.filteredpersonsOptions$ = of(this._filter(name));
    if(this.person !=undefined) {
      this.monitoringTypeChanged = false
  }
  }

  clearAutoCompleteValue() {
    this.personName = "";
    // this.originalPersonName = this.personName;
    // this.filteredpersonsOptions$ = of(this._filter(this.personName));
  };

  personChangeAutocomplete(event) {
    if (event==this.personName) return;
    let p;
    p = this.persons.filter(elem=>elem['_id']== event._id)[0];
    console.log("another event ", event, "this person is ", this.person, "p is ", p)
    if (p) {
      this.personName=p.firstName+" "+p.lastName;
      this.person=p;
    } 
  };

  changeVal(event) {
 
  };

    getDataFromPerson() {
      console.log("--------------------------------------- this person is ", this.person)
      try {
        console.log("------------getDataFromPerson ", this.person, this.personName, this.allocation)
    if (this.allocation &&  this.personName==undefined) this.personName=this.allocation.firstName + " " + this.allocation.lastName;
     if (this.person) this.allocation.personId=this.person._id;
     if (this.person) this.allocation.cnp=this.person.cnp;
     if (this.person) this.allocation.firstName=this.person.firstName;
     if (this.person) this.allocation.email=this.person.email;
     if (this.person) this.allocation.initial=this.person.initial;
     if (this.person) this.allocation.lastName=this.person.lastName;
     if (this.person) this.allocation.maidenName=this.person.maidenName;
     if (this.person) this.allocation.middleName=this.person.middleName;
     if (this.person) this.allocation.motherMaidenName=this.person.motherMaidenName;
     if (this.person) this.allocation.nationality=this.person.nationality;
     if (this.person) this.allocation.phone=this.person.phone;
   if(this.person) {
    
    // this.allocation.monitoring_type = this.person.monitoring_type.monitoring_type
   }
     try{
     if (this.person) this.allocation.practice=this.person.practice[0];
     } catch(e) {
      console.log("getData failed because ", e)
      this.allocation.practice="";
     }
     try {
     if (this.person) this.allocation.profession=this.person.profession[0];
    } catch(e) {
      this.allocation.profession="";
    }
     if (this.person) this.allocation.sex=this.person.sex;
     if (this.person) this.allocation.uid=this.person.uid;
     if (this.person) this.allocation.code=this.person.code;
     //if (this.person) this.allocation.active=this.person.active;
     if (this.person)  this.allocation.dob=this.datepipe.transform(this.person.dob,'yyyy-MM-dd');
   
      this.allocation.wearStartDate= this.datepipe.transform(this.allocation.wearStartDate,'yyyy-MM-dd');
      this.allocation.wearEndDate= this.datepipe.transform(this.allocation.wearEndDate,'yyyy-MM-dd');
      this.allocation.allocStart= this.datepipe.transform(this.allocation.allocStart, 'yyyy-MM-dd');
      this.allocation.allocEnd= this.datepipe.transform(this.allocation.allocEnd, 'yyyy-MM-dd');
      this.errored=false;
      } catch(e) {
        console.log("______________________ ERROR ", e)
        this.errored=true;
        this.showToast('danger', this.ls.strings.danger, '')
      }
     
    }
    async updateValues() {
    console.log("--------------------------", this.allocation, this.person);
    
    this.allocation.id=this.allocation.dosimeterId;

    if (this.oldDosimeterId!=this.allocation.dosimeterId) { await this.validateAsignment();}
    if (this.validDosimeter!=true) { return;} 
    if (this.oldDosimeterId!=this.allocation.dosimeterId && this.validDosimeter==true) {
      console.log("entering here")
      await this.dataService.swapAssignedDosimeters(this.oldDosimeterId, this.allocation.dosimeterId, this.auth.user.token);
    }

    if(this.allocation.personId && this.person!=undefined && this.allocation.personId!=this.person._id) {
      let _r = await this.dataService.swapAllocationPersons(this.allocation._id, this.allocation.personId, this.person._id, this.auth.user.token );
      if (_r.status!=200) {
        this.showToast('danger', this.ls.strings.monitoring_type_not_compatible, "");
        return;
      } 
    };

    console.log("THIS PERSON MT IS ", this.allocation)
    this.getDataFromPerson();
    console.log("THIS.PERSON is ", this.person)
    console.log("ALLOCATED MONI TYPE ", this.allocatedMonitoringType.selected)
    this.allocation.frequency = this.allocatedMonitoringType.selected.monitoring_frequency;
    this.allocation.monitoring_type = this.allocatedMonitoringType.selected.monitoring_type;

     //compar practice din alloc cu practice din persoana
  
     if (
       this.allocation.practice && this.person &&
       Array.isArray(this.person.practice) &&
       this.person.practice.some((p) => p === this.allocation.practice)
     ) {
       try {
         let _pt = this.practice.find(el => el.value === this.allocation.practice);
         if (_pt) {
           this.allocation.practice_code = _pt.key;
         }
       } catch (e) {}
     }
     //////////////////////////

    if (this.allocatedMonitoringType.selected.mt!=undefined) {
    try {
    this.allocation.monitoringKey = this.allocatedMonitoringType.selected.mt.key;
    
    this.allocation.price = this.allocatedMonitoringType.selected.mt.price;
    this.allocation.measurement_unit = this.allocatedMonitoringType.selected.mt.service.measurement_unit;
    this.allocation.radiation_types = this.allocatedMonitoringType.selected.mt.service.radiation_types;
    this.allocation.position = this.allocatedPosition.selected.value 
    console.log("POSITION IS ", this.allocatedPosition.selected.value)
  } catch(e) { console.log(" errored on ", e)}
    }
    this.allocation.id=this.allocation.dosimeterId;

    let resp = await this.dataService.updateDoseRecord(this.auth.user.token, this.allocation);
 
    if (this.allocationDatesChanged) {
      console.log(" ALLOCATION START AND OR END HAVE CHANGED FROM ", this.doseInitialAllocStart, this.doseInitialAllocEnd);
      
      let _umt=this.person.monitoring_type.filter(el=>el.monitoring_type==this.allocation.monitoring_type
        && el.allocStart==this.utils.getFormattedDate(this.doseInitialAllocStart,"yyyy-MM-dd")
        && el.allocEnd==this.utils.getFormattedDate(this.doseInitialAllocEnd,"yyyy-MM-dd"))[0]

      console.log("MUST UPDATE FOR ",_umt)
      if (_umt) {
        _umt.allocStart=this.allocation.allocStart;
        _umt.allocEnd=this.allocation.allocEnd;
      }

    let r = await this.dataService.updatePersonMonitoringType (this.auth.user.token, this.person)
      console.log("this allocation after update is ", this.allocation, this.person)
    }
    if(resp.status==200) {
      this.showToast('success', this.ls.strings.success, "");
      this.ref.close(this.allocation);
    }
      else {
        this.showToast('danger', this.ls.strings.danger, '')
        this.ref.close();
      }
    
    };

   onChangeEvent(event) {
    const input =(event.target as HTMLInputElement);
    this.checked = input.checked;
  };

  async isSuperAdmin():Promise<Boolean>{
    return this.auth.isSuperadminRole()
  };


  allocationDateChangedEvent() {
    this.allocationDatesChanged=true;
  };

  selectionOrganizationChange(event) {
    console.log("event is ", event)
    let o=this.suborganizations.filter(elem=>elem['denumire']==event)[0];
    console.log("o is ", o);
    this.allocation.suborganizationId=o._id;
    this.allocation.department = o.denumire;
    this.allocation.suborganization_code = o.organization_code;
    this.allocation.suborganization_nr_reg_com = o.nrRegCom;      ;
    this.allocation.suborganization_iban = o.iban;
    this.allocation.suborganization_cui = o.cui;
      try {
        this.allocation.address.active = o.workplace_address.active;
    this.allocation.address.apartment = o.workplace_address.apartment;
    this.allocation.address.buildingName = o.workplace_address.buildingName;
    this.allocation.address.city = o.workplace_address.city;
    this.allocation.address.country = o.workplace_address.country;
    this.allocation.address.county = o.workplace_address.county;
    this.allocation.address.details = o.workplace_address.details;
    this.allocation.address.floor = o.workplace_address.floor;
    this.allocation.address.line1 = o.workplace_address.line1;
    this.allocation.address.line2 = o.workplace_address.line2;
    this.allocation.address.line3 = o.workplace_address.line3;
    this.allocation.address.line4 = o.workplace_address.line4;
    this.allocation.address.lined = o.workplace_address.lined;
    this.allocation.address.name = o.workplace_address.name;
    this.allocation.address.shipping_type = o.workplace_address.shipping_type;
    this.allocation.address.street = o.workplace_address.street;
    this.allocation.address.streetNumber = o.workplace_address.streetNumber;
    this.allocation.address.zipCode = o.workplace_address.zipCode;

      } catch(e) {this.showToast("danger", this.ls.strings.suborganization_swap_not_done,'');}
  };

  async getSuborganizations() {
    console.log("get sub orgs yyy", "Preffered org is ", this.allocation.organizationId)
    let resp = await this.data.getUserSuborganizations(this.allocation.organizationId, this.auth.user.token, true);
    console.log("get sub orgs resp is ", resp)
    if (resp.status==200 || resp.length>0) {
    this.suborganizations = resp;
    
    } else {
      this.suborganizations=[];
    }
  }

  async validateAsignment() {
    this.validDosimeter=false;
    let r:any= await this.dataService.validateAssigment(this.auth.user.token, this.allocation);
    console.log(r, "valid dosi is ", this.validDosimeter)
    if (r.compatible == true && r.exists==true && r.wrong_state==false)  {
      console.log("ok");
      this.showToast("success", this.ls.strings.success,'');
      this.validDosimeter=true;
     
    }
    else 
    if (r.exists!=true) {
      this.showToast("danger", this.ls.strings.danger_dosi,''); 
    }
    else if (r.wrong_state==true && (r.currentState!='free'))  {
      await this.openRecordOverview(this.allocation, this.allocation.id);
      this.showToast("danger", this.ls.strings.wrong_state,''); }
    else if (r.compatible!=true) {
      this.showToast("danger", this.ls.strings.incompatible_dosi,''); 
    }
    else if (r.currentState=='free') {
      this.showToast("danger", this.ls.strings.unasignable_free,''); 
    }
    return r;
  }
  
  async getMonitoringType () {

  };

  async initSelectComment() {
    let _c=new CodeComment();
    let respComm = await this.data.getGeneric(this.auth.user.token, _c);
    this.codeComment = respComm.docs;
    try {
    this.codeComment = this.codeComment.filter(elem=>elem['comment'].key=="dose_record");
     this.codeCommentSelect.selected=this.codeComment.filter(elem=>elem.value==this.allocation.comment.value)[0];
    } catch(e) { }
  };

  async configComments() {
    this.showComments = await this.utils.getShowComments();
    console.log("config comments is ", this.showComments);

  };

  async initSelectComments() {
    let _c=new CodeComment();
    let respComm = await this.data.getGeneric(this.auth.user.token, _c);
    this.codeComments = respComm.docs;
    try {
    this.codeComments = this.codeComments.filter(elem=>elem['comment'].key=="dose_record");
    for (let allocationComments of this.allocation.comments) {
      let selected = this.codeComments.find(elem => elem.value == allocationComments.value);
      if (selected) {
        this.selectedComments.push(selected);
      }
      console.log("selectedComments are ", this.selectedComments)
    }
    
  
    } catch(e) { }
  }

  onCommentChange(event) {
  this.allocation.comment = event
    
  };

  onCommentsChange(event) {
    this.allocation.comments = event
      
    };

     async getPractice() {
        let resp = await this.data.getGeneric(this.auth.user.token, new Practice());
        this.practice = resp.docs;
        
      }

  async openRecordOverview(_allocation:any, new_dosimeterId, ) {
    var otherAlloc = (await this.dataService.findAssignedDosimeter(this.authService.user.token, this.allocation));;
    this.dialogService.open(AssignCompareComponent, {
      context:{
        currentAllocation: this.allocation,
        otherAllocation: otherAlloc.allocation

      },
      hasBackdrop: true,
      closeOnBackdropClick: false
    })
    .onClose.subscribe(result => {
      if(result == true){
        this.ref.close(true);
      }
    });
    this.allocation.dosimeterId = this.currentDosimeter;
  };

  changeClassified(event) {
    const input =(event.target as HTMLInputElement);
    this.checked = input.checked;
    
  };

 
  dismiss() {
     this.ref.close()
  }

}
